import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Typography, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby';
import { filterByLocale } from 'utils/filterDataByLocale';

const useStyles = makeStyles(theme => ({
  promoLogo: {
    maxWidth: 100,
  },
}));

const About = props => {
  const data = useStaticQuery(graphql`
    query {
      pages: allContentfulPages {
        edges {
          node {
            title
            slug
          }
        }
      }
      header: allContentfulHeader(
        filter: {
          slug: { eq: "selling-should-be-easy-affordable-and-rewarding" }
        }
      ) {
        edges {
          node {
            title
            subTitle
            slug
            firstButton
            secondButton
            image {
              file {
                contentType
                fileName
                url
              }
            }
            imageLinkOne {
              file {
                url
              }
            }
            node_locale
          }
        }
      }
    }
  `);
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  let filteredData = filterByLocale(data.header.edges);

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      {filteredData.map((edge, index) => {
        return (
          <Grid
            key={index}
            container
            justify="space-between"
            spacing={isMd ? 4 : 0}
          >
            <Grid item xs={12} md={6} data-aos={'fade-up'}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  <SectionHeader
                    title={<span>{edge.node.title}</span>}
                    subtitle={edge.node.subTitle}
                    ctaGroup={[
                      <Button
                        variant="contained"
                        color="primary"
                        size={isMd ? 'large' : 'medium'}
                        href="https://account.vend.one/"
                      >
                        {edge.node.firstButton}
                      </Button>,
                      <Button
                        variant="outlined"
                        color="primary"
                        size={isMd ? 'large' : 'medium'}
                      >
                        {edge.node.secondButton}
                      </Button>,
                    ]}
                    align={isMd ? 'left' : 'center'}
                    disableGutter
                    titleVariant="h3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="primary"
                    align={isMd ? 'left' : 'center'}
                    gutterBottom
                  >
                    TRUSTED BY:
                  </Typography>
                  <Image
                    src={edge.node.imageLinkOne.file.url}
                    width="180px"
                    // src="https://assets.maccarianagency.com/the-front/illustrations/dashboard-extended.svg"
                    alt="Vend Home"
                  />
                  {/* <Grid container justify="space-between">
                    {data.map((partner, index) => (
                      <Grid
                        item
                        container
                        justify="center"
                        xs={6}
                        sm={2}
                        key={index}
                      >
                        <Image
                          src={partner.logo}
                          alt={partner.name}
                          className={classes.promoLogo}
                          lazy={false}
                        />
                      </Grid>
                    ))}
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="center"
              xs={12}
              md={6}
              data-aos={'fade-up'}
            >
              <Image
                src={edge.node.image.file.url}
                width="400px"
                // src="https://assets.maccarianagency.com/the-front/illustrations/dashboard-extended.svg"
                alt="Vend Home"
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

About.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  // data: PropTypes.array.isRequired,
  data: PropTypes.array,
};

export default About;
